import React, {useState, useEffect} from 'react'

import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import TopicBox from './topic-box';
import NavBar from '../navigation/nav-bar';
import GradeSelector from './grade-selector';
import 'firebase/compat/firestore';
import Bar from '../treehouse/bar';

import './subject.css'
const Subject = (props)=>{

    console.log(props)

    var auxGrade = props.subjectGrade[props.subject]
    if(!auxGrade){
        auxGrade="7"
    }
    
    const [notionData, setNotionData] = useState([]);

    const [topics, setTopics] = useState([])

    const [grade, setGrade] = useState(auxGrade)
    const [toggle, setToggle] = useState(false)
    const [topicProgress, setTopicProgress] = useState({});
    const [globalProgress, setGlobalProgress] = useState({
        total: 0,
        gained: 0
    });
    const [subjectAverage, setSubjectAverage] = useState(0);

    const subjectsWithGrades = {
        "matematicas": true, 
        "ciencias": true, 
        "espanol": true, 
        "ciudadania": true
    }

    useEffect(()=>{
        // Reset progress states
        setTopicProgress({});
        setGlobalProgress({
            total: 0,
            gained: 0
        });
        
        const query = {
            database:"1adac672a1464d60aff992a1d1c000b4",
            filter: {
                and: [
                    {
                        property: 'Subject',
                        select: {
                            equals: props.subject
                        }
                    },
                    {
                        property: 'Grade',
                        select: {
                            equals: grade
                        }
                    }
                ]
            },
            sorts:[
                {
                    property: 'Sequence',
                    direction: 'ascending'
                }
            ]
        }

        const notionQuery = firebase.functions().httpsCallable("notionQuery")
        notionQuery(query)
            .then(response=>{
                setNotionData(response.data);
                console.log(response.data)
                setTopics(response.data.results)
            })
        
        const data = props.subjectGrade
        data[props.subject] = grade

        firebase.firestore().collection("curricular-progress").doc(props.uid).set(data)
        
    }, [grade])

    useEffect(() => {
        topics.forEach(topic => {
            const notionQuery = firebase.functions().httpsCallable("notionQuery")
            
            const activityQuery = {
                database: "396c609207ef4df3a3ade19f60f37646",
                filter: {
                    and: [
                        {
                            property: 'Topic',
                            relation: {
                                contains: topic.id
                            }
                        }
                    ]
                }
            };

            notionQuery(activityQuery).then(response => {
                const activities = response.data.results;
                
                firebase.firestore()
                    .collection("students/" + props.uid + "/uploads")
                    .onSnapshot((snap) => {
                        let uploadedActivities = {};
                        snap.docs.forEach(doc => {
                            uploadedActivities[doc.id] = doc.data();
                        });

                        let total = 0;
                        let gained = 0;
                        
                        activities.forEach(activity => {
                            const points = activity.properties?.tiempo?.number || 0;
                            total += points;
                            
                            const uploadedActivity = uploadedActivities[activity.id];
                            if (uploadedActivity?.status === "approved") {
                                const score = uploadedActivity.score || 0;
                                gained += (points * score / 100);
                            }
                        });

                        setTopicProgress(prev => ({
                            ...prev,
                            [topic.id]: {
                                total: total,
                                gained: Math.round(gained)
                            }
                        }));
                    });
            });
        });
    }, [topics, props.uid]);

    useEffect(() => {
        const totals = Object.values(topicProgress).reduce((acc, curr) => ({
            total: acc.total + curr.total,
            gained: acc.gained + curr.gained
        }), { total: 0, gained: 0 });
        
        setGlobalProgress(totals);
    }, [topicProgress]);

    console.log(props)

    const handleToggle=(grade)=>{
        console.log(grade)
        setToggle(!toggle)

        setGrade(grade)

        if(grade){

            firebase.firestore().collection("curricular-progress").doc(props.uid).get().then(doc=>{

                var progress = doc.data()

                if(!progress){
                    progress = {}
                }

                progress[props.subject] = grade

                firebase.firestore().collection("curricular-progress").doc(props.uid).set(progress)
            })

            
        }
    }

    

    return(
        
        <div className="topic-selector-view">
            <img className="lms-avatar" src={props.avatar}></img>
            <NavBar
                
                handleBack={props.handleViewChange}
                backView="app"
                title={props.subject}
            
            />

            <div className="global-progress-container">
                <div className="global-progress-info">
                    <h2>Progreso Global</h2>
                    <p>Dominio: {Math.round(globalProgress.gained*100/globalProgress.total)}%</p>
                    {/* <p>Promedio: {averageScore}%</p> */}
                </div>
                

                <div className="subject-progress-bar-background">
                    
                    {globalProgress.gained != 0 ?
                        <div className="topic-progress-bar" style={{width: ((globalProgress.gained*100)/globalProgress.total)+"%"}}>
                            <p>{globalProgress.gained}/{globalProgress.total}</p>
                        </div>
                        :
                        <div className="topic-progress-bar" style={{backgroundColor:"transparent"}}>
                            <p>{globalProgress.gained}/{globalProgress.total}</p>
                        </div>
                    }
                                
                </div>
            </div>

            {subjectsWithGrades[props.subject]&&
                <GradeSelector
                grade={grade}
                setGrade={setGrade}
                toggle={toggle}
                handleToggle={handleToggle}
            />

            }
            
            <div className="topics-container">
                {topics.map(topic => {
                    const progress = topicProgress[topic.id] || { total: 0, gained: 0 };


                    var width = (progress.gained*100)/progress.total

                    const style = {
                        width: width+"%",
                        backgroundColor: props.color,
                    }


                    return(
                        <div className='shelf-spot' key={topic.id}>
                            {/* <div className="topic-points">
                                <p>{progress.gained}/{progress.total}</p>
                            </div>
                            <Bar
                                max={progress.total}
                                progress={progress.gained}
                                color={'#3DD869'}
                            /> */}

                            <div className="topic-progress-bar-background">
                                {progress.gained != 0 ?
                                    <div className="topic-progress-bar" style={style}>
                                        <p>{progress.gained}/{progress.total}</p>
                                    </div>
                                    :
                                    <div className="topic-progress-bar" style={{backgroundColor:"transparent"}}>
                                        <p>{progress.gained}/{progress.total}</p>
                                    </div>
                                }
                                
                            </div>

                            <TopicBox
                                name={topic.properties.Name.title[0].plain_text}
                                points={progress}
                                topic_id={topic.id}
                                img={""}
                                viewTopic={props.viewTopic}
                            />
                        </div>
                    )
                })}
            </div>
            

        </div>
    )
}

export default Subject